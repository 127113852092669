/* tslint:disable */
/* eslint-disable */
/**
 * NOIRLab API
 * NOIRLab Djangoplicity API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Image } from '../models';
// @ts-ignore
import { PaginatedImageItemList } from '../models';
// @ts-ignore
import { PaginatedVideoMiniList } from '../models';
// @ts-ignore
import { Video } from '../models';
/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [category] The web category identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, galaxies, illustrations
         * @param {Array<number>} [facility] 
         * @param {MediaImagesListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {string} [search] 
         * @param {boolean} [tiny] If true the response will be a simplified version of the image with only the minimal required fields. Default: false
         * @param {MediaImagesListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaImagesList: async (category?: string, facility?: Array<number>, lang?: MediaImagesListLangEnum, page?: number, pageSize?: number, search?: string, tiny?: boolean, translationMode?: MediaImagesListTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/v2/media/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (facility) {
                localVarQueryParameter['facility'] = facility;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (tiny !== undefined) {
                localVarQueryParameter['tiny'] = tiny;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MediaImagesRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {MediaImagesRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaImagesRetrieve: async (id: string, lang?: MediaImagesRetrieveLangEnum, translationMode?: MediaImagesRetrieveTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaImagesRetrieve', 'id', id)
            const localVarPath = `/public/api/v2/media/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [category] The web category identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, galaxies, illustrations
         * @param {MediaVideosListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {MediaVideosListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaVideosList: async (category?: string, lang?: MediaVideosListLangEnum, page?: number, pageSize?: number, translationMode?: MediaVideosListTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/v2/media/videos/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MediaVideosRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {MediaVideosRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaVideosRetrieve: async (id: string, lang?: MediaVideosRetrieveLangEnum, translationMode?: MediaVideosRetrieveTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaVideosRetrieve', 'id', id)
            const localVarPath = `/public/api/v2/media/videos/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [category] The web category identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, galaxies, illustrations
         * @param {Array<number>} [facility] 
         * @param {MediaImagesListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {string} [search] 
         * @param {boolean} [tiny] If true the response will be a simplified version of the image with only the minimal required fields. Default: false
         * @param {MediaImagesListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaImagesList(category?: string, facility?: Array<number>, lang?: MediaImagesListLangEnum, page?: number, pageSize?: number, search?: string, tiny?: boolean, translationMode?: MediaImagesListTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedImageItemList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaImagesList(category, facility, lang, page, pageSize, search, tiny, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MediaImagesRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {MediaImagesRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaImagesRetrieve(id: string, lang?: MediaImagesRetrieveLangEnum, translationMode?: MediaImagesRetrieveTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaImagesRetrieve(id, lang, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [category] The web category identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, galaxies, illustrations
         * @param {MediaVideosListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {MediaVideosListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaVideosList(category?: string, lang?: MediaVideosListLangEnum, page?: number, pageSize?: number, translationMode?: MediaVideosListTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedVideoMiniList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaVideosList(category, lang, page, pageSize, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MediaVideosRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {MediaVideosRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaVideosRetrieve(id: string, lang?: MediaVideosRetrieveLangEnum, translationMode?: MediaVideosRetrieveTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Video>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaVideosRetrieve(id, lang, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApiFp(configuration)
    return {
        /**
         * 
         * @param {MediaApiMediaImagesListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaImagesList(requestParameters: MediaApiMediaImagesListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PaginatedImageItemList> {
            return localVarFp.mediaImagesList(requestParameters.category, requestParameters.facility, requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.search, requestParameters.tiny, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MediaApiMediaImagesRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaImagesRetrieve(requestParameters: MediaApiMediaImagesRetrieveRequest, options?: AxiosRequestConfig): AxiosPromise<Image> {
            return localVarFp.mediaImagesRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MediaApiMediaVideosListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaVideosList(requestParameters: MediaApiMediaVideosListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PaginatedVideoMiniList> {
            return localVarFp.mediaVideosList(requestParameters.category, requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MediaApiMediaVideosRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaVideosRetrieve(requestParameters: MediaApiMediaVideosRetrieveRequest, options?: AxiosRequestConfig): AxiosPromise<Video> {
            return localVarFp.mediaVideosRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mediaImagesList operation in MediaApi.
 * @export
 * @interface MediaApiMediaImagesListRequest
 */
export interface MediaApiMediaImagesListRequest {
    /**
     * The web category identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, galaxies, illustrations
     * @type {string}
     * @memberof MediaApiMediaImagesList
     */
    readonly category?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof MediaApiMediaImagesList
     */
    readonly facility?: Array<number>

    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof MediaApiMediaImagesList
     */
    readonly lang?: MediaImagesListLangEnum

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MediaApiMediaImagesList
     */
    readonly page?: number

    /**
     * Number of results to return per page. Max: 50, Default: 10
     * @type {number}
     * @memberof MediaApiMediaImagesList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof MediaApiMediaImagesList
     */
    readonly search?: string

    /**
     * If true the response will be a simplified version of the image with only the minimal required fields. Default: false
     * @type {boolean}
     * @memberof MediaApiMediaImagesList
     */
    readonly tiny?: boolean

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof MediaApiMediaImagesList
     */
    readonly translationMode?: MediaImagesListTranslationModeEnum
}

/**
 * Request parameters for mediaImagesRetrieve operation in MediaApi.
 * @export
 * @interface MediaApiMediaImagesRetrieveRequest
 */
export interface MediaApiMediaImagesRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaApiMediaImagesRetrieve
     */
    readonly id: string

    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof MediaApiMediaImagesRetrieve
     */
    readonly lang?: MediaImagesRetrieveLangEnum

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof MediaApiMediaImagesRetrieve
     */
    readonly translationMode?: MediaImagesRetrieveTranslationModeEnum
}

/**
 * Request parameters for mediaVideosList operation in MediaApi.
 * @export
 * @interface MediaApiMediaVideosListRequest
 */
export interface MediaApiMediaVideosListRequest {
    /**
     * The web category identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, galaxies, illustrations
     * @type {string}
     * @memberof MediaApiMediaVideosList
     */
    readonly category?: string

    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof MediaApiMediaVideosList
     */
    readonly lang?: MediaVideosListLangEnum

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MediaApiMediaVideosList
     */
    readonly page?: number

    /**
     * Number of results to return per page. Max: 50, Default: 10
     * @type {number}
     * @memberof MediaApiMediaVideosList
     */
    readonly pageSize?: number

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof MediaApiMediaVideosList
     */
    readonly translationMode?: MediaVideosListTranslationModeEnum
}

/**
 * Request parameters for mediaVideosRetrieve operation in MediaApi.
 * @export
 * @interface MediaApiMediaVideosRetrieveRequest
 */
export interface MediaApiMediaVideosRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaApiMediaVideosRetrieve
     */
    readonly id: string

    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof MediaApiMediaVideosRetrieve
     */
    readonly lang?: MediaVideosRetrieveLangEnum

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof MediaApiMediaVideosRetrieve
     */
    readonly translationMode?: MediaVideosRetrieveTranslationModeEnum
}

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @param {MediaApiMediaImagesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public mediaImagesList(requestParameters: MediaApiMediaImagesListRequest = {}, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).mediaImagesList(requestParameters.category, requestParameters.facility, requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.search, requestParameters.tiny, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApiMediaImagesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public mediaImagesRetrieve(requestParameters: MediaApiMediaImagesRetrieveRequest, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).mediaImagesRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApiMediaVideosListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public mediaVideosList(requestParameters: MediaApiMediaVideosListRequest = {}, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).mediaVideosList(requestParameters.category, requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApiMediaVideosRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public mediaVideosRetrieve(requestParameters: MediaApiMediaVideosRetrieveRequest, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).mediaVideosRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const MediaImagesListLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type MediaImagesListLangEnum = typeof MediaImagesListLangEnum[keyof typeof MediaImagesListLangEnum];
/**
 * @export
 */
export const MediaImagesListTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type MediaImagesListTranslationModeEnum = typeof MediaImagesListTranslationModeEnum[keyof typeof MediaImagesListTranslationModeEnum];
/**
 * @export
 */
export const MediaImagesRetrieveLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type MediaImagesRetrieveLangEnum = typeof MediaImagesRetrieveLangEnum[keyof typeof MediaImagesRetrieveLangEnum];
/**
 * @export
 */
export const MediaImagesRetrieveTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type MediaImagesRetrieveTranslationModeEnum = typeof MediaImagesRetrieveTranslationModeEnum[keyof typeof MediaImagesRetrieveTranslationModeEnum];
/**
 * @export
 */
export const MediaVideosListLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type MediaVideosListLangEnum = typeof MediaVideosListLangEnum[keyof typeof MediaVideosListLangEnum];
/**
 * @export
 */
export const MediaVideosListTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type MediaVideosListTranslationModeEnum = typeof MediaVideosListTranslationModeEnum[keyof typeof MediaVideosListTranslationModeEnum];
/**
 * @export
 */
export const MediaVideosRetrieveLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type MediaVideosRetrieveLangEnum = typeof MediaVideosRetrieveLangEnum[keyof typeof MediaVideosRetrieveLangEnum];
/**
 * @export
 */
export const MediaVideosRetrieveTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type MediaVideosRetrieveTranslationModeEnum = typeof MediaVideosRetrieveTranslationModeEnum[keyof typeof MediaVideosRetrieveTranslationModeEnum];
