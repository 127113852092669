import { ref, watch } from 'vue'

export default function usePageNumberAndQuery() {
  const query = ref<string>(getInitialQuery())
  const page = ref<number>(getInitialPage())

  watch([query, page], updateParamsInURL)

  // Checks if there is a page parameter in the URL and use it
  function getInitialPage() {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      return parseInt(urlParams.get('page') || '1')
    } catch {
      return 1
    }
  }

  function getInitialQuery() {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('query') || ''
  }

  function updateParamsInURL() {
    const newURL = new URL(window.location.href)
    newURL.searchParams.set('page', page.value + '')
    newURL.searchParams.delete('query')
    if (query.value) {
      newURL.searchParams.set('query', query.value)
    }
    window.history.replaceState({}, document.title, newURL)
  }

  return {
    query,
    page,
  }
}
