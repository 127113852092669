import '../scss/app.scss'
// esm-bundlers includes the compiler, the compiler is needed for in-DOM templates or templates via inline JavaScript strings
// See: https://v3.vuejs.org/guide/installation.html#explanation-of-different-builds
import { createApp } from 'vue/dist/vue.esm-bundler'
import NOIRLabHeader from './components/NOIRLabHeader.vue'
import ParallaxNews from './components/ParallaxNews.vue'
import CarouselNews from './components/CarouselNews.vue'
import LatestAnnouncements from './components/LatestAnnouncements.vue'
import NewsList from './components/NewsList.vue'
import ImagesList from './components/ImagesList.vue'
import VideosList from './components/VideosList.vue'
import AnnouncementList from './components/AnnouncementList.vue'
import FancyTitle from './components/FancyTitle.vue'
import AnimatedSiteMap from './components/AnimatedSiteMap.vue'

const Root = {
  delimiters: ['[[', ']]'],
}

const app = createApp(Root)
app.component('noirlab-header', NOIRLabHeader)
app.component('parallax-news', ParallaxNews)
app.component('carousel-news', CarouselNews)
app.component('latest-announcements', LatestAnnouncements)
app.component('news-list', NewsList)
app.component('announcement-list', AnnouncementList)
app.component('images-list', ImagesList)
app.component('videos-list', VideosList)
app.component('fancy-title', FancyTitle)
app.component('animated-site-map', AnimatedSiteMap)
app.config.globalProperties.window = window
app.mount('#vueApp')

// On window load add the class load to the body
window.addEventListener('load', () => {
  document.body.classList.add('loaded')
})
