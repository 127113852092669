import dayjs from 'dayjs'
import { NOIRLAB_DATES_FORMAT } from '@/js/const'

// Note, add all the formats you will need
export type NOIRLabImageFormat =
  | 'original'
  | 'large'
  | 'medium'
  | 'news'
  | 'screen'
  | 'thumbs'
  | 'thumb300y'
  | 'thumb350x'
  | 'thumb700x'
  | 'wallpaper4'
  | 'wallpaper3'
  | 'wallpaper5'

export default function useNOIRLabArchives() {
  function formatDate(dateISO: string) {
    return dayjs(dateISO).format(NOIRLAB_DATES_FORMAT)
  }

  function getImageFormat(imageId: string, format: NOIRLabImageFormat = 'screen'): string {
    return `https://noirlab.edu/public/media/archives/images/${format}/${imageId}.jpg`
  }

  function getMainNewsImage(newsItem: any, format: NOIRLabImageFormat = 'screen'): string {
    if (newsItem.main_visual) {
      return getImageFormat(newsItem.main_visual, format)
    }
    return ''
  }

  function getMainAnnouncementImage(announcementItem: any, format: NOIRLabImageFormat = 'screen'): string {
    if (announcementItem.main_visual) {
      return getImageFormat(announcementItem.main_visual, format)
    }
    return ''
  }

  function getNewsURLById(id: string) {
    return `https://noirlab.edu/public/news/${id}`
  }

  function getAnnouncementURLById(id: string) {
    return `https://noirlab.edu/public/announcements/${id}`
  }

  return {
    formatDate,
    getMainAnnouncementImage,
    getMainNewsImage,
    getNewsURLById,
    getAnnouncementURLById,
    getImageFormat,
  }
}
