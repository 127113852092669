/* tslint:disable */
/* eslint-disable */
/**
 * NOIRLab API
 * NOIRLab Djangoplicity API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Announcement } from '../models';
// @ts-ignore
import { PaginatedAnnouncementMiniList } from '../models';
/**
 * AnnouncementsApi - axios parameter creator
 * @export
 */
export const AnnouncementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AnnouncementsListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {string} [program] The program identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, noirlab
         * @param {AnnouncementsListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsList: async (lang?: AnnouncementsListLangEnum, page?: number, pageSize?: number, program?: string, translationMode?: AnnouncementsListTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/v2/announcements/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (program !== undefined) {
                localVarQueryParameter['program'] = program;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AnnouncementsRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {AnnouncementsRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsRetrieve: async (id: string, lang?: AnnouncementsRetrieveLangEnum, translationMode?: AnnouncementsRetrieveTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsRetrieve', 'id', id)
            const localVarPath = `/public/api/v2/announcements/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsApi - functional programming interface
 * @export
 */
export const AnnouncementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AnnouncementsListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {string} [program] The program identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, noirlab
         * @param {AnnouncementsListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsList(lang?: AnnouncementsListLangEnum, page?: number, pageSize?: number, program?: string, translationMode?: AnnouncementsListTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAnnouncementMiniList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsList(lang, page, pageSize, program, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AnnouncementsRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {AnnouncementsRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsRetrieve(id: string, lang?: AnnouncementsRetrieveLangEnum, translationMode?: AnnouncementsRetrieveTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Announcement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsRetrieve(id, lang, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementsApi - factory interface
 * @export
 */
export const AnnouncementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementsApiFp(configuration)
    return {
        /**
         * 
         * @param {AnnouncementsApiAnnouncementsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsList(requestParameters: AnnouncementsApiAnnouncementsListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PaginatedAnnouncementMiniList> {
            return localVarFp.announcementsList(requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.program, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnnouncementsApiAnnouncementsRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsRetrieve(requestParameters: AnnouncementsApiAnnouncementsRetrieveRequest, options?: AxiosRequestConfig): AxiosPromise<Announcement> {
            return localVarFp.announcementsRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for announcementsList operation in AnnouncementsApi.
 * @export
 * @interface AnnouncementsApiAnnouncementsListRequest
 */
export interface AnnouncementsApiAnnouncementsListRequest {
    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof AnnouncementsApiAnnouncementsList
     */
    readonly lang?: AnnouncementsListLangEnum

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof AnnouncementsApiAnnouncementsList
     */
    readonly page?: number

    /**
     * Number of results to return per page. Max: 50, Default: 10
     * @type {number}
     * @memberof AnnouncementsApiAnnouncementsList
     */
    readonly pageSize?: number

    /**
     * The program identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, noirlab
     * @type {string}
     * @memberof AnnouncementsApiAnnouncementsList
     */
    readonly program?: string

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof AnnouncementsApiAnnouncementsList
     */
    readonly translationMode?: AnnouncementsListTranslationModeEnum
}

/**
 * Request parameters for announcementsRetrieve operation in AnnouncementsApi.
 * @export
 * @interface AnnouncementsApiAnnouncementsRetrieveRequest
 */
export interface AnnouncementsApiAnnouncementsRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsApiAnnouncementsRetrieve
     */
    readonly id: string

    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof AnnouncementsApiAnnouncementsRetrieve
     */
    readonly lang?: AnnouncementsRetrieveLangEnum

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof AnnouncementsApiAnnouncementsRetrieve
     */
    readonly translationMode?: AnnouncementsRetrieveTranslationModeEnum
}

/**
 * AnnouncementsApi - object-oriented interface
 * @export
 * @class AnnouncementsApi
 * @extends {BaseAPI}
 */
export class AnnouncementsApi extends BaseAPI {
    /**
     * 
     * @param {AnnouncementsApiAnnouncementsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public announcementsList(requestParameters: AnnouncementsApiAnnouncementsListRequest = {}, options?: AxiosRequestConfig) {
        return AnnouncementsApiFp(this.configuration).announcementsList(requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.program, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnouncementsApiAnnouncementsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public announcementsRetrieve(requestParameters: AnnouncementsApiAnnouncementsRetrieveRequest, options?: AxiosRequestConfig) {
        return AnnouncementsApiFp(this.configuration).announcementsRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AnnouncementsListLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type AnnouncementsListLangEnum = typeof AnnouncementsListLangEnum[keyof typeof AnnouncementsListLangEnum];
/**
 * @export
 */
export const AnnouncementsListTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type AnnouncementsListTranslationModeEnum = typeof AnnouncementsListTranslationModeEnum[keyof typeof AnnouncementsListTranslationModeEnum];
/**
 * @export
 */
export const AnnouncementsRetrieveLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type AnnouncementsRetrieveLangEnum = typeof AnnouncementsRetrieveLangEnum[keyof typeof AnnouncementsRetrieveLangEnum];
/**
 * @export
 */
export const AnnouncementsRetrieveTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type AnnouncementsRetrieveTranslationModeEnum = typeof AnnouncementsRetrieveTranslationModeEnum[keyof typeof AnnouncementsRetrieveTranslationModeEnum];
