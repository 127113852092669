<script setup>
import { useWindowScroll } from '@vueuse/core'
import { useElementSize } from '@vueuse/core'
import { computed, ref } from 'vue'

const props = defineProps({
  transparentEnabled: Boolean,
})

const header = ref(null)
const { height } = useElementSize(header)

const { y } = useWindowScroll()
const transparent = computed(() => {
  return props.transparentEnabled ? y.value === 0 : false
})
</script>

<template>
  <header v-bind="$attrs" id="header" ref="header" :class="{ 'bg-dark': !transparent }">
    <slot :transparent="transparent"></slot>
  </header>
  <div v-if="!transparentEnabled" class="header-space-filler" :style="{ height: height + 'px' }"></div>
</template>

<style lang="scss">
#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: transparent;
  border-bottom: 1px solid #ffffff40;

  &,
  & > div {
    transition: background-color 0.6s;
  }

  .nav-scroller {
    position: relative;
    z-index: 4;
    overflow: visible;

    .nav {
      display: flex;
      flex-wrap: nowrap;
      padding-bottom: 1rem;
      margin-top: -1px;
      overflow-x: auto;
      text-align: center;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
    }
  }

  .dropdown-item:hover {
    text-decoration: none;
  }
}
</style>
