/* tslint:disable */
/* eslint-disable */
/**
 * NOIRLab API
 * NOIRLab Djangoplicity API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedReleaseMiniList } from '../models';
// @ts-ignore
import { Release } from '../models';
/**
 * ReleasesApi - axios parameter creator
 * @export
 */
export const ReleasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReleasesListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {string} [program] The program identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, noirlab
         * @param {ReleasesListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releasesList: async (lang?: ReleasesListLangEnum, page?: number, pageSize?: number, program?: string, translationMode?: ReleasesListTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/v2/releases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (program !== undefined) {
                localVarQueryParameter['program'] = program;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ReleasesRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {ReleasesRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releasesRetrieve: async (id: string, lang?: ReleasesRetrieveLangEnum, translationMode?: ReleasesRetrieveTranslationModeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('releasesRetrieve', 'id', id)
            const localVarPath = `/public/api/v2/releases/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (translationMode !== undefined) {
                localVarQueryParameter['translation_mode'] = translationMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReleasesApi - functional programming interface
 * @export
 */
export const ReleasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReleasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ReleasesListLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page. Max: 50, Default: 10
         * @param {string} [program] The program identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, noirlab
         * @param {ReleasesListTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releasesList(lang?: ReleasesListLangEnum, page?: number, pageSize?: number, program?: string, translationMode?: ReleasesListTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedReleaseMiniList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.releasesList(lang, page, pageSize, program, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ReleasesRetrieveLangEnum} [lang] The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
         * @param {ReleasesRetrieveTranslationModeEnum} [translationMode] Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releasesRetrieve(id: string, lang?: ReleasesRetrieveLangEnum, translationMode?: ReleasesRetrieveTranslationModeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Release>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.releasesRetrieve(id, lang, translationMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReleasesApi - factory interface
 * @export
 */
export const ReleasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReleasesApiFp(configuration)
    return {
        /**
         * 
         * @param {ReleasesApiReleasesListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releasesList(requestParameters: ReleasesApiReleasesListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PaginatedReleaseMiniList> {
            return localVarFp.releasesList(requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.program, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReleasesApiReleasesRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releasesRetrieve(requestParameters: ReleasesApiReleasesRetrieveRequest, options?: AxiosRequestConfig): AxiosPromise<Release> {
            return localVarFp.releasesRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for releasesList operation in ReleasesApi.
 * @export
 * @interface ReleasesApiReleasesListRequest
 */
export interface ReleasesApiReleasesListRequest {
    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof ReleasesApiReleasesList
     */
    readonly lang?: ReleasesListLangEnum

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ReleasesApiReleasesList
     */
    readonly page?: number

    /**
     * Number of results to return per page. Max: 50, Default: 10
     * @type {number}
     * @memberof ReleasesApiReleasesList
     */
    readonly pageSize?: number

    /**
     * The program identifier, e.g: kpno, rubin, gemini, ctio, csdc, noao, useltp, noirlab
     * @type {string}
     * @memberof ReleasesApiReleasesList
     */
    readonly program?: string

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof ReleasesApiReleasesList
     */
    readonly translationMode?: ReleasesListTranslationModeEnum
}

/**
 * Request parameters for releasesRetrieve operation in ReleasesApi.
 * @export
 * @interface ReleasesApiReleasesRetrieveRequest
 */
export interface ReleasesApiReleasesRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof ReleasesApiReleasesRetrieve
     */
    readonly id: string

    /**
     * The language to use, used along with \&quot;translation_mode\&quot;, default: user \&quot;preferred_language\&quot; cookie
     * @type {'en' | 'es'}
     * @memberof ReleasesApiReleasesRetrieve
     */
    readonly lang?: ReleasesRetrieveLangEnum

    /**
     * Defines if the original version of an object is returned in case the translation is not found, use \&quot;fallback\&quot; to retrieve the original content if no translation exist, or \&quot;language\&quot; to return translated content only. Default: \&quot;language\&quot;
     * @type {'fallback' | 'language'}
     * @memberof ReleasesApiReleasesRetrieve
     */
    readonly translationMode?: ReleasesRetrieveTranslationModeEnum
}

/**
 * ReleasesApi - object-oriented interface
 * @export
 * @class ReleasesApi
 * @extends {BaseAPI}
 */
export class ReleasesApi extends BaseAPI {
    /**
     * 
     * @param {ReleasesApiReleasesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public releasesList(requestParameters: ReleasesApiReleasesListRequest = {}, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).releasesList(requestParameters.lang, requestParameters.page, requestParameters.pageSize, requestParameters.program, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReleasesApiReleasesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public releasesRetrieve(requestParameters: ReleasesApiReleasesRetrieveRequest, options?: AxiosRequestConfig) {
        return ReleasesApiFp(this.configuration).releasesRetrieve(requestParameters.id, requestParameters.lang, requestParameters.translationMode, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ReleasesListLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type ReleasesListLangEnum = typeof ReleasesListLangEnum[keyof typeof ReleasesListLangEnum];
/**
 * @export
 */
export const ReleasesListTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type ReleasesListTranslationModeEnum = typeof ReleasesListTranslationModeEnum[keyof typeof ReleasesListTranslationModeEnum];
/**
 * @export
 */
export const ReleasesRetrieveLangEnum = {
    En: 'en',
    Es: 'es'
} as const;
export type ReleasesRetrieveLangEnum = typeof ReleasesRetrieveLangEnum[keyof typeof ReleasesRetrieveLangEnum];
/**
 * @export
 */
export const ReleasesRetrieveTranslationModeEnum = {
    Fallback: 'fallback',
    Language: 'language'
} as const;
export type ReleasesRetrieveTranslationModeEnum = typeof ReleasesRetrieveTranslationModeEnum[keyof typeof ReleasesRetrieveTranslationModeEnum];
